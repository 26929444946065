import { Component, OnInit, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { ProjectUtil } from "../../application/services/project-util";
import { UtilService, AppConfig } from "fx-core";
import { CommonPortletService } from "../services/commonportlet.service";

@Component({
  selector: "app-student-additional-info",
  templateUrl: "./student-additional-info.component.html",
  styleUrls: ["./student-additional-info.component.scss"],
})
export class StudentAdditionalInfoComponent implements OnInit {
  @Input() contextData: any;
  @Input() modalData: any;
  @Input() routeData: any;

  currentContext: any = {
    studentId: null,
  };

  lastContribution: any[] = [
    { id: "Month", text: "Month" },
    { id: "Year", text: "Year" },
  ];

  healthStatus: any[] = [
    { id: "Fit", text: "Fit" },
    { id: "Not Fit", text: "Not Fit" },
  ];

  learningStatus: any[] = [
    { id: "Low Level", text: "Low Level" },
    { id: "Medium Level", text: "Medium Level" },
    { id: "High Level", text: "High Level" },
  ];

  otherDetailsForm: any = {
    donarName: "",
    donarId: "",
    source: "",
    yearOfAssociate: "",
    statusOfSupport: "",
    lastContribution: "",
    schoolName: "",
    schoolResult: "",
    maxMarks: "",
    percentage: "",
    height: "",
    weight: "",
    noYears: "",
    talent: "",
    noOfGuruKul: "",
    healthStatus: "",
    learningStatus: "",
    repetationOfClass: "",
    remarks: "",
    personDisability: "",
    minority: "",
    povertyLine: "",
    rationNo: "",
    periodStay: "",
    fatherEdu: "",
    motherEdu: "",
    empStatusFather: "",
    empStatusMother: "",
    statusOfIndividual: "",
    otherNgoStatus: "",
  };
  isChildEducation: boolean = false;

  constructor(
    private projectUtil: ProjectUtil,
    protected utl: UtilService,
    private api: CommonPortletService,
    public router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.currentContext.studentId =
      this.routeData.studentId || this.routeData.id;
    let routeData = this.utl.nav.peek();
    
    if (routeData && routeData.targetData && routeData.targetData.id) {
      this.otherDetailsForm = routeData.targetData;
    }

    const user = this.utl.session.getObject('authtoken').user;
    if(user && user.institution && user.institution.isChildEducation) {
      this.isChildEducation = true; 
    }
  }

  validateAndSubmit() {
    let saveWithOutFile = "student/student/UpdateStudent";
    let body: any = { data: this.otherDetailsForm };

    this.api.saveData(body, saveWithOutFile).then(
      (data: any) => {
        this.utl.alert.showSuccessMsg();
      },
      (err) => {
        const { error } = err;
        if (error) {
          let msg = "Student details failed to updated";
          if (error.error && error.error.message) {
            msg = error.error.message;
          }
          this.utl.alert.showErrorMsg(msg);
        } else {
          this.utl.alert.showErrorMsg("Student details failed to updated");
        }
      }
    );
  }
}
